:root{
  --poolPhotoGap: .5%;
  --poolPhotoBaseWidth: 32.5%;
}
.poolphoto {
  position: relative;
}

.poolphoto_sm{
  width: var(--poolPhotoBaseWidth)
}
.poolphoto_md{
  width: calc(var(--poolPhotoBaseWidth)*2 + var(--poolPhotoGap))
}
.poolphoto_lg{
  width: calc(var(--poolPhotoBaseWidth)*3 + 2*var(--poolPhotoGap))
}
.photo_pool {
  display: flex;
  flex-flow: row wrap;      
  flex: 1 1 auto;
  gap: 5px;
  overflow: scroll;
  justify-content: start;
  align-items: center;
}

.poolphoto_border{
  position: absolute;
  border: 2px solid yellow;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.selected_photo_area{
 flex: auto;
 max-width: 50%;
 width: 50%;
 max-height: 100%;
 overflow: hidden;
 display: flex;
 flex-direction: column;
 margin: 0 5px;
}

.selected_photo_byHeight{
  position: relative;
  height: 100%;
}
.selected_photo_byWidth{
  position: relative;
  width: 100%;
}
.selected_photo_overlay_wrapper{
  position: relative;
  min-width: 0;
  min-height: 0;
}
.selected_photo_overlay{
  position: absolute;  
}

.poolphoto_overlay{
  position: absolute;  
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
  border-radius: 25%;
}

.poolphoto_missing{
  border: 1px solid black;
  padding: 5px;
  background-color: pink;
  border-radius: 5px;
}

.hidden{
  display: none;
}

.select_photo_controls{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin: 2px 0;
}



.photo_picker{
  display: flex;
  flex-flow: row wrap;
}

.photo_picker .poolphoto{
  height: 33vh;
}

